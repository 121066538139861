<template>
  <div class="tabs" id="fixedBar">
    <div
      v-for="(tab, index) in tabs"
      :key="'tab' + index"
      class="tab"
      :class="[{ active: index === activeTab }]"
      @click="clickTab(index)"
    >
      <div class="image" :class="{ meridien: tab.name === '寒舍艾美' }">
        <img :src="tab.image" alt="" />
      </div>
      <div class="name">
        {{ tab.name }}
      </div>
    </div>
  </div>
</template>

<script>
import bus from '@/assets/js/bus.js'

export default {
  name: 'Tabs',
  components: {},
  props: {
    tabs: {
      type: Array,
      default() {
        return []
      },
    },
    activeTab: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {}
  },
  methods: {},
  mounted() {},
  unmounted() {},
  setup() {
    function clickTab(index) {
      bus.emit('clickTab', index)
    }
    return { clickTab }
  },
}
</script>

<style scoped lang="scss">
.tabs {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 12px;
  justify-items: center;
  background-color: #faf8f2;
  padding: 0;

  .tab {
    width: 100%;
    height: 96px;
    border-radius: 8px;
    color: #545454;
    background-color: #fefdfb;
    position: relative;

    &.mt-12 {
      margin-top: 12px;
    }

    .image {
      width: 78%;
      margin: 0 auto;

      img {
        width: 100%;
        height: 100%;
      }
      &.meridien {
        width: 90%;
        position: absolute;
        top: -5%;
        left: 5%;
      }
    }
    .name {
      position: absolute;
      top: 70px;
      width: 100%;
      font-size: 13px;
      text-align: center;
      line-height: 19px;
    }

    &.active {
      background-color: #f0eada;
    }
  }
}

::-webkit-scrollbar {
  display: none;
}

@media (min-width: 390px) {
  .tabs {
    .tab {
      .image {
        max-width: 80px;
        max-height: 80px;
        &.meridien {
          max-width: 90px;
          max-height: 90px;
          left: calc(50% - 45px);
        }
      }
    }
  }
}
</style>
