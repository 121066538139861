<template>
  <div class="home">
    <Header :info="seasonPlan" />
    <div
      class="event-content"
      :class="{ 'reduce-height': !filtedEventList.length }"
      id="tab-container"
    >
      <TabsSquare :tabs="tabs" :activeTab="activeTab" class="mb-6" />
      <transition name="fade" mode="out-in">
        <div class="event-list">
          <Chips
            :chips="chips"
            :activeChip="activeChip"
            emit="clickChip"
            class="mb-2"
          />
          <ShowCard
            v-for="(event, index) in filtedEventList"
            class="list-item"
            :key="'event' + index"
            :info="event"
            @click="toEvent(event)"
          />
          <div v-if="!filtedEventList.length" class="note">
            <div class="no-event">目前沒有活動</div>
          </div>
        </div>
      </transition>
    </div>
    <div class="goTop" :class="{ active: goTopSwitch }" @click="goTop">
      <img :src="iconArrowUp" alt="" />
    </div>
  </div>
</template>

<script>
import Header from '@/components/header.vue'
import ShowCard from '@/components/showCard.vue'
import TabsSquare from '@/components/tabsSquare.vue'
import Chips from '@/components/chips.vue'
import bus from '@/assets/js/bus.js'
import api from '@/api/api'
import { ref } from 'vue'
import { useStore } from 'vuex'
import { useRoute } from 'vue-router'
import iconAll from '../assets/img/hotel-logo/company.png'
import iconArrowUp from '../assets/img/icon/arrow-up.svg'

export default {
  name: 'EventList',
  components: {
    Header,
    ShowCard,
    TabsSquare,
    Chips,
  },
  data() {
    return {
      goTopSwitch: false,
      scrollWithThrottle: null,
    }
  },
  computed: {
    activeHotelId() {
      return this.tabs[this.activeTab].id
    },
    filtedEventList() {
      const type =
        this.activeChip === '住房優惠'
          ? 1
          : this.activeChip === '美饌方案'
          ? 2
          : this.activeChip === '優惠好康'
          ? 4
          : 0

      if (this.activeChip === '全部優惠' && this.activeTab === 0) {
        return this.eventList
      } else if (this.activeChip === '全部優惠' && this.activeTab !== 0) {
        return this.eventList.filter(
          event => event.hotelId === this.activeHotelId
        )
      } else if (this.activeChip !== '全部優惠' && this.activeTab === 0) {
        return this.eventList.filter(event => event.type === type)
      } else {
        return this.eventList.filter(
          event => event.type === type && event.hotelId === this.activeHotelId
        )
      }
    },
  },
  methods: {
    toEvent(event) {
      // eslint-disable-next-line
      gtag('event', 'click', {
        event_id: '003',
        event_name: '全部優惠',
        location: '季節方案',
        event_category: `季節方案--${event.title}`,
        event_label: event.title,
        value: 3,
      })
      this.$router.push({
        name: 'Event',
        params: { eid: event.activityId },
      })
    },
    handleScroll() {
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop

      this.goTopSwitch = scrollTop >= 750 ? true : false
    },
    throttle(func, timeout = 250) {
      let last
      let timer

      return function() {
        const context = this
        const args = arguments
        const now = +new Date()

        if (last && now < last + timeout) {
          clearTimeout(timer)
          timer = setTimeout(function() {
            last = now
            func.apply(context, args)
          }, timeout)
        } else {
          last = now
          func.apply(context, args)
        }
      }
    },
  },
  created() {
    bus.on('clickTab', index => {
      this.activeChip = '全部優惠'
      this.activeTab = index
    })
    bus.on('clickChip', index => {
      this.activeChip = this.chips[index].title
    })
  },
  mounted() {
    this.scrollWithThrottle = this.throttle(this.handleScroll)
    window.addEventListener('scroll', this.scrollWithThrottle)
  },
  unmounted() {
    window.removeEventListener('scroll', this.scrollWithThrottle)
  },
  beforeUnmount() {
    bus.all.clear()
  },
  setup() {
    const route = useRoute()
    const store = useStore()

    const tabs = ref([{ name: '全部', image: iconAll }])
    const hotelList = store.state.hotelList
    const hotelIdList = ref(['0'])
    hotelList.forEach(element => {
      const obj = {
        id: element.id,
        name: element.shortName,
        image: element.logoUrl,
      }
      tabs.value.push(obj)
      hotelIdList.value.push(element.id)
    })

    let eventList = ref([])
    let seasonPlan = ref({
      title: '季節方案',
      subTitle: '熱情款待來自世界各地的旅客，精采演繹全新風貌',
    })
    const chips = ref([
      { title: '全部優惠' },
      { title: '住房優惠' },
      { title: '美饌方案' },
      { title: '優惠好康' },
    ])
    let activeTab = ref(0)
    activeTab.value =
      route.query.tab && route.query.tab != '0'
        ? hotelIdList.value.findIndex(id => id == route.query.tab)
        : 0
    let activeChip = ref('')
    activeChip.value =
      chips.value[route.query.chip ? route.query.chip : 0].title
    api
      .SeasonPlan()
      .then(result => {
        seasonPlan.value = result.data
      })
      .catch(err => {
        console.error('取得活動失敗', err)
      })
    api
      .ActivityList({
        hotelId: '',
        restaurantId: '',
        pageType: 1,
      })
      .then(result => {
        eventList.value = result.data
      })
      .catch(err => {
        console.error('取得活動失敗', err)
      })

    const goTop = () => {
      window.scrollTo({
        top: 0,
        behavior: 'smooth',
      })
    }

    return {
      seasonPlan,
      eventList,
      activeTab,
      hotelIdList,
      activeChip,
      chips,
      tabs,
      goTop,
      iconArrowUp,
    }
  },
}
</script>

<style scoped lang="scss">
.home {
  position: relative;
}
.event-content {
  padding: 7px 5.33% 99px;
  min-height: calc(100vh - 72px);
  .title {
    padding-bottom: 17px;
  }
}
.reduce-height {
  padding: 32px 5.33% 20px;
  min-height: 60px;
}
.note {
  width: 100%;
  display: flex;
  justify-content: center;
}
.no-event {
  min-width: 100px;
  height: 16px;
  line-height: 16px;
  font-size: 12px;
  line-height: 17px;
  color: #979797;
  position: relative;
  margin-top: 40px;
  text-align: center;

  &::before {
    content: '';
    width: calc(50vw - 5.5vw - 50%);
    height: 1px;
    background-color: #e5e5e5;
    position: absolute;
    top: 8px;
    left: calc((50vw - 5.5vw - 50%) * -1);
  }
  &::after {
    content: '';
    width: calc(50vw - 5.5vw - 50%);
    height: 1px;
    background-color: #e5e5e5;
    position: absolute;
    top: 8px;
    left: 100%;
  }
}

.goTop {
  width: 48px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.97);
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.08);
  border-radius: 100px;
  position: fixed;
  bottom: -60px;
  right: 6px;
  transition: bottom 0.5s ease;
  img {
    width: 15px;
    height: 8px;
  }

  &.active {
    bottom: 16px;
  }
}
</style>
