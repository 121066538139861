<template>
  <div class="chips">
    <div
      v-for="(chip, index) in chips"
      :key="'chip' + index"
      class="chip"
      :class="{ active: activeChip === chip.title }"
      @click="clickChip(index)"
    >
      {{ chip.title }}
    </div>
  </div>
</template>

<script>
import bus from '@/assets/js/bus.js'
export default {
  name: 'Chips',
  components: {},
  props: {
    chips: {
      type: Array,
      default() {
        return []
      },
    },
    activeChip: {
      type: String,
      default: '',
    },
    emit: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    function clickChip(index) {
      if (props.emit) {
        bus.emit(props.emit, index)
      }
    }
    return { clickChip }
  },
}
</script>

<style scoped lang="scss">
.chips {
  .chip {
    font-weight: normal;
    color: #b79961;
    font-size: 13px;
    line-height: 26px;
    letter-spacing: -0.32px;
    display: inline-block;
    padding: 2px 12px;
    background: #fefdfb;
    border: 1px solid rgba(206, 177, 125, 0.3);
    border-radius: 100px;
    margin-right: 7px;
    margin-bottom: 5px;
    &.active {
      background: #ceb17d;
      color: #ffffff;
    }
  }
}
</style>
