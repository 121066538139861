<template>
  <div @click="click" class="show-card">
    <div class="show-image">
      <div
        v-if="info.type > 0"
        class="label left"
        :class="[{ purple: info.type == 2 }, { brown: info.type == 4 }]"
      >
        {{
          info.type === 1
            ? '住房優惠'
            : info.type === 2
            ? '美饌方案'
            : info.type === 4
            ? '優惠券'
            : ''
        }}
      </div>
      <img :src="info.image" />
      <button
        @click.stop="showMenu"
        v-if="info.menus && info.menus.length"
        class="label right menu"
      >
        <div class="icon"><img src="@/assets/img/icon/menu.svg" /></div>
        線上菜單
      </button>
    </div>
    <div class="show-title">{{ info.title }}</div>
    <div class="show-subTitle">{{ info.subTitle }}</div>
  </div>
</template>

<script>
// type:
// 0:normal
// 1:room
// 2:cuisine
// 3:news
import bus from '@/assets/js/bus.js'
export default {
  name: 'ShowCard',
  components: {},
  props: {
    info: {
      type: Object,
      default() {
        return {
          image: '',
          type: 0,
          title: '',
          subTitle: '',
          menus: [],
        }
      },
    },
  },
  methods: {
    showMenu() {
      bus.emit('showMenu', this.info.menus)
    },
  },
}
</script>

<style scoped lang="scss">
.show-card {
  overflow: hidden;
  background: #fefdfb;
  box-shadow: 0px 4px 16px #ede9db;
  border-radius: 8px;
  @apply pb-4;
  & ~ & {
    margin-top: 16px;
  }
}
.show-image {
  position: relative;
  min-height: 169px;
  min-width: 100%;
  img {
    @apply w-full;
  }
  .label {
    color: #fff;
    font-size: 14px;
    font-weight: normal;
    line-height: 24px;
    letter-spacing: -0.32px;
    position: absolute;
    padding: 4px 10px 4px 16px;
    background: linear-gradient(
        90deg,
        rgba(205, 161, 61, 0.7) 0%,
        rgba(224, 196, 112, 0.7) 100%
      ),
      linear-gradient(
        90deg,
        rgba(255, 255, 255, 0.9) 0%,
        rgba(255, 255, 255, 0) 100%
      );
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    &.left {
      left: 0;
      top: 16px;
      border-radius: 0px 100px 100px 0px;
    }
    &.menu {
      background: rgba(0, 0, 0, 0.6);
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      .icon {
        width: 16px;
        display: inline-block;
        margin-right: 6px;
        vertical-align: middle;
      }
    }
    &.right {
      right: 0;
      bottom: 8px;
      border-radius: 100px 0px 0px 100px;
    }
    &.purple {
      background: linear-gradient(
          90deg,
          rgba(187, 174, 206, 0.7) 0%,
          rgba(146, 118, 188, 0.7) 100%
        ),
        linear-gradient(
          90deg,
          rgba(255, 255, 255, 0.9) 0%,
          rgba(255, 255, 255, 0) 100%
        );
    }
    &.brown {
      background: linear-gradient(
          90deg,
          rgba(205, 156, 82, 0.7) 0%,
          rgba(208, 101, 40, 0.7) 100%
        ),
        linear-gradient(
          90deg,
          rgba(255, 255, 255, 0.9) 0%,
          rgba(255, 255, 255, 0) 100%
        );
    }
  }
}
.show-title {
  font-size: 16px;
  line-height: 28px;
  letter-spacing: -0.32px;
  color: #a66621;
  @apply font-medium px-4 pt-2 pb-1;
}
.show-subTitle {
  font-size: 15px;
  line-height: 24px;
  letter-spacing: 0.01em;
  color: hsl(0, 0%, 33%);
  overflow: hidden;
  word-break: break-all;
  max-height: 48px;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  @apply font-light px-4;
}
</style>
